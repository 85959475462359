
.products {
    padding: 150px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-back {
    position: absolute;
    max-width: 1920px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
}
.products-back-two {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #E1E1E1;
    z-index: -2;
}

.products-inner {
    display: flex;
    flex-direction: column;

}

.products-title {
    display: flex;
    flex-direction: column;
}

.products-title-text {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 16px;
    color: black;
}

.products-bg {
    margin-top: 20px;
    position: relative;
}

.products-bg::before {
    position: absolute;
    content: '';
    background: #A1A4AC;
    width: 100%;
    height: 1px;
}

.products-bg::after {
    position: absolute;
    content: '';
    background: #f57b0a;
    border-radius: 15px;
    width: 50px;
    height: 10px;
    top: -5px;
}

.products-items {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.products-item {
    position: relative;
    max-width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin: 15px 0;
}

.products-item-img {
    display: block;
    max-width: 450px;
    max-height: 250px;
    overflow: hidden;
    pointer-events: none;
}

.products-item-img img {
    opacity: 0.9;
    width: 100%;
    height: auto;
    transition: 300ms;
}

.products-item:hover .products-item-img img {
    opacity: 1;
}

.products-item-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #fff;
    text-align: center;

    position: absolute;
    background-color: none;
    width: 100%;
    padding: 25px 0;
    transition: 300ms;
}


.products-item:hover .products-item-text {
    background-color: #f57b0a;
}


.products-item-hover {
    display: flex;
    align-items: center;
    position: absolute;
    background: rgba(250, 242, 242, 0.9);
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 20px;
    visibility: hidden;
    border: 1px solid #A1A4AC;
    opacity: 0;
    transition: opacity 300ms ease-out 0ms;
    z-index: 1;
    width: 100%;
}

.products-item-hover-text {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: black;
    line-height: 16px;
    font-family: sans-serif;

    width: 100%;
    height: 100%;
}

.products-item-hover-text span {
    margin: 3px 0;
}


.products-item:hover .products-item-hover {
    left: 30%;
    top: -50%;
    opacity: 1;
    visibility: visible;

}


.products-item:hover .products-item-img img {
    opacity: 1;
}

@media (max-width: 1387px) {
    .products-items {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .products-item {
        margin: 15px 15px;
    }
 
}


@media (max-width: 1220px) {

    .products-item:hover .products-item-hover {
        left: 5%;
        top: -50%;
        opacity: 1;
        visibility: visible;
    
    }
    

   
}



@media (max-width: 860px) {

    .products-item {
        position: relative;
        /* max-width: 350px; */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        margin: 15px 10px;
        user-select: none;
    }

    .products-item-text {
        font-size: 16px;
        line-height: 18px;
        padding: 25px 0;
    }
 
}


@media (max-width: 790px) {
    .products-item {
        max-width: 400px;
        margin: 35px 10px;
    }

    .products-item-hover-text {
        padding: 10px 20px;
        font-size: 13px;
        line-height: 17px;
    
        width: 100%;
        height: 100%;
    }

}


@media (max-width: 685px) {
    
    .products-item:hover .products-item-hover {
        left: 20%;
        top: -50%;
        opacity: 1;
        visibility: visible;
    }


    .products-item-hover-text {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 16px;
    
        width: 100%;
        height: 100%;
    }
}


@media (max-width: 600px) {

    .products-title-text {
        font-size: 23px;
    }

    .products-items {
        margin-top: 45px;
    }

    .products-item:hover .products-item-hover {
        left: 0%;
        top: -60%;
        opacity: 1;
        visibility: visible;
    }


}

@media (max-width: 430px) {
    
    .products {
        margin-bottom: 90px;
          padding: 100px 0;

    }

 

    .dproducts-item:hover .products-item-hover-one {
        left: 0%;
        top: -25%;
        opacity: 1;
        visibility: visible;
    }


.products-title-text {
    font-size: 20px;
}

}

@media (max-width: 375px) {
    .products-item-text {
        font-size: 15px;
        line-height: 18px;
        padding: 15px 0;
    }
}
body {
  position: relative;
  background-color: #F8F8F8;
  color: #FFFFFF;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 16px;
  font-weight: 400;

}

*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.cont {
  width: 100%;
  max-width: 1430px;
  padding: 0 10px;
  margin: 0 auto;
}




.intro {
    position: relative;
    margin-bottom: 75px;
}

.image-back {
    display: flex;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: auto;

    z-index: -1;
}

.intro-bg {
    display: flex;
    align-items: center;
    max-height: 800px;
    max-width: 1920px;
    overflow: hidden;
    pointer-events: none;
}

.intro-bg img {
    vertical-align: middle;
    width: 100%;
}

.intro-bg video {
    width: 100%;
    vertical-align: middle;
}


.intro-items {
    position: relative;
    user-select: none;
  
}

.intro-item {
    position: absolute;
    bottom: 350px;
    left: 160px;
}

.intro-item-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #fff;
    max-width: 600px;
    padding: 20px 0 55px 25px;
    background-color: #f57b0a;
}

.intro-item-text {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    color: #ffffffd2;
    background: rgba(0, 0, 0, 0.7);
    padding: 25px 10px 25px 20px;
    max-width: 600px;
    margin-top: -20px;
    margin-left: 40px;
}

.intro-item-text-link {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffffd2;
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
}

.intro-item-text-link:hover {
    text-decoration: none;
}


@media (max-width:1300px) {
    .intro-item {
        position: absolute;
        bottom: 250px;
        left: 100px;
    }
}


@media (max-width:1100px) {

  

    .intro-item-title {
        font-size: 18px;
        color: #fff;
        max-width: 580px;
        padding: 20px 0 55px 25px;
    }
    
    .intro-item-text {
        font-size: 14px;
        padding: 25px 10px 25px 20px;
        max-width: 580px;
    }

    .intro-item {
        position: absolute;
        bottom: 200px;
        left: 80px;
    }
}

@media (max-width: 990px) {
    .intro-item {
        position: absolute;
        bottom: 100px;
        left: 80px;
    }

    
}

@media (max-width: 860px) {

   

    .intro-item-title {
        font-size: 18px;
        color: #fff;
        max-width: 500px;
        padding: 20px 0 55px 25px;
    }
    
    .intro-item-text {
        font-size: 13px;
        padding: 25px 10px 25px 20px;
        max-width: 500px;
    }
}


@media (max-width: 700px) {

    .intro-item {
        position: absolute;
        bottom: 60px;
        left: 80px;
    }
    .intro-item-title {
        font-size: 17px;
        color: #fff;
        max-width: 300px;
        padding: 20px 0 20px 25px;
    }
    
    .intro-item-text {
        font-size: 13px;
        padding: 15px 10px 10px 20px;
        max-width: 300px;
        margin-top: 0;
        margin-left: 0;
    }

    .intro-item-text-link {
        font-size: 12px;
    }
}



@media (max-width:600px) {
    .intro-bg {
        max-height: 500px;
    }

    .intro-bg img {
        width: unset;
        height: 100%;
    }

    .intro-bg video {
        width: unset;
        height: 100%;
    }

  
}

@media (max-width: 450px) {
    .intro-item {
        position: absolute;
        bottom: 100px;
        left: 40px;
    }

    .intro-item-title {
        font-size: 16px;
        color: #fff;
        max-width: 300px;
        padding: 20px 0 20px 25px;
    }

    .intro-item-text {
        font-size: 12px;
        padding: 15px 10px 10px 20px;
        max-width: 300px;
        margin-top: 0;
        margin-left: 0;
    }

}

@media (max-width: 400px) {
    .intro-item {
        bottom: 100px;
        left: 30px;
    }

    .intro-item-title {
        font-size: 15px;
        color: #fff;
        max-width: 300px;
        padding: 10px 0 10px 25px;
    }

    .intro-item-text {
        font-size: 12px;
        padding: 15px 8px 8px 20px;
        max-width: 300px;
        margin-top: 0;
        margin-left: 0;
    }

 

}


@media (max-width: 380px) {
  

    .intro-item-title {
        font-size: 16px;
        color: #fff;
        max-width: 300px;
        padding: 10px 0 10px 25px;
    }

    .intro-item-text {
        font-size: 12px;
        padding: 15px 8px 8px 20px;
        max-width: 300px;
        margin-top: 0;
        margin-left: 0;
    }

}


@media (max-width: 290px) {
    .intro-item {
        left: 0px;
    }
} 


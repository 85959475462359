.about {
    margin-top: 90px;
    padding: 100px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
}

.about-inner {
    display: flex;
    flex-direction: column;
    text-align: center; 
}


.about-title {
    margin-bottom: 70px;
    position: relative;
}
.about-title h3 {
    font-style: normal;
    font-weight:500;
    font-size: 30px;
    line-height: 16px;
    color: gray;
}

.about-title-bg {
    position: absolute;
    background: #848F9D;
    height: 1px;
    bottom: -10px;
    left: 0;
    right: 0;
}

.about-items {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-img {
     max-width: 700px;
     overflow: hidden;
}

.about-img img {
    width: 100%;
    height: auto;
}   


.about-item {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    margin-left: 50px;
    align-items: center;
}

.about-item img {
    max-width: 200px;
    margin-bottom: 30px;
}


.about-text {
    font-family:Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: black;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 8px;
    padding: 35px;
    transition: 300ms;
}

.about-text:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.about-text span {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
}


@media (max-width: 1400px) {
   .about-items {
    display: flex;
    flex-direction: column;
   } 

   .about-item {
    margin-top: 20px;
    margin-left: 0px;
   }
   
}



@media (max-width: 600px) {
    .about-title h3 {
        font-size: 23px;
    }

    .about-item img {
        max-width: 160px;
    }

    .about-text {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .about-text {
        font-size: 14px;
        padding: 20px;
    }
}


@media (max-width: 430px) {
    .about-title h3 {
        font-size: 15px;
    }
}

@media (max-width: 400px) {
    .about-text {
        font-size: 13px;
        padding: 10px;
    }
}

